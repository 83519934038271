<template>
  <div></div>
</template>

<script>
import { authService } from '@/services/http'

export default {
  name: 'AuthCallback',
  mounted() {
    this.handleAuthentication()
  },
  methods: {
    async handleAuthentication() {
      try {
        const { token } = this.$route.query

        if (!token) {
          this.redirectToLogin()
          return
        }

        if (!authService || !authService.refreshAuthTokens) {
          this.redirectToLogin()
          return
        }

        await authService.refreshAuthTokens(token)

        this.$router.push('/')
      } catch (error) {
        this.redirectToLogin()
      }
    },
    redirectToLogin() {
      this.$router.push('/login')
    }
  }
}
</script>
